import { isSizeType, ThemeSizesTypes } from "../types";
import { rem } from "../utils";

export const flexResponse = (value: number | ThemeSizesTypes) => {
  let space = "";
  let verticalSpace = "";
  let gap = "";

  if (isSizeType(value)) {
    space = `var(--space-${value})`;
    verticalSpace = `var(--space-${value})`;

    if (value === "xxl") {
      verticalSpace = `var(--space-xl)`;
    } else if (value === "xl") {
      verticalSpace = `var(--space-lg)`;
    } else if (value === "lg") {
      verticalSpace = `var(--space-md)`;
    } else if (value === "md") {
      verticalSpace = `var(--space-sm)`;
    } else if (value === "sm") {
      verticalSpace = `var(--space-sm)`;
    }

    gap = `grid-gap: ${verticalSpace} ${space}`;
  } else {
    space = rem(value);
  }

  return `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${gap};
    
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        > * {
            margin-right: ${space};
            margin-bottom: ${space};
        }
    
        > :last-child {
            margin-right: 0px;
        }
    }
    
    @supports (not (gap: 0px)) {
        > * {
            margin-right: ${space};
            margin-bottom: ${space};
        }
    
        > :last-child {
            margin-right: 0px;
        }
    }
    `;
};
