import { css } from "@emotion/react";

import * as ui from "../../../ui";

export type Position = "left" | "right";

export const section = css`
  ${ui.column(0)};

  @media (min-width: ${ui.breakpoints.lg}px) {
    ${ui.row(0)};
  }
`;

export const container = (position: Position) => css`
  order: 1;
  width: 100%;
  ${ui.column("lg")};
  justify-content: center;
  margin-bottom: ${ui.rem("xxl")};
  padding: ${ui.rem("lg")};

  @media (min-width: ${ui.breakpoints.sm}px) {
    padding: ${ui.rem("xl")};
  }

  @media (min-width: ${ui.breakpoints.lg}px) {
    order: ${position === "left" ? "-1" : "1"};
  }
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(50)};
  line-height: ${ui.rem(66)};
  color: #000000;
`;

export const form = css`
  ${ui.column("md")};
`;

export const inputContainer = css`
  ${ui.column("sm")};
  border-bottom: 2px solid #545454;
`;

export const inputTitle = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: ${ui.rem(20)};
  line-height: ${ui.rem(30)};
  color: #545454;
`;

export const input = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(16)};
  line-height: ${ui.rem(30)};
  color: #817f7f;

  :focus,
  :visited,
  :focus-within,
  :focus-visible {
    outline: none;
  }
`;

export const buttonContainer = css`
  margin-top: ${ui.rem("md")};
  ${ui.row("sm")};
  ${ui.flexCenter()};
  padding: ${ui.rem("sm")} ${ui.rem("lg")};
  background: #ec6036;
  color: #ffffff;
  align-self: flex-start;
  transition: all 300ms;
  cursor: pointer;

  svg {
    color: #ffffff;
  }

  :hover {
    background-color: #d3522b;
    color: #ffffff;

    svg {
      color: #ffffff;
    }
  }
`;

export const button = css``;

export const background = (position: Position, src: string) => css`
  width: 100%;
  display: flex;
  justify-content: ${position === "left" ? "flex-end" : "flex-start"};
  align-items: flex-start;
  padding: ${ui.rem("lg")};
  background-image: url("${src}");
  background-position: center;
  background-size: cover;

  @media (min-width: ${ui.breakpoints.sm}px) {
    padding: ${ui.rem("xl")};
  }

  @media (min-width: ${ui.breakpoints.lg}px) {
    height: 100vh;
  }
`;

export const backgroundLogo = css``;

export const footer = css`
  a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: ${ui.rem(14)};
    line-height: ${ui.rem(30)};
    color: #817f7f;
    transition: color 300ms;

    :hover {
      color: #454545;
    }
  }
`;
