import { useEffect } from "react";
import { Global, css } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

import { Routes } from "./routes";
import { AlertProvider, breakpoints, styleReset, UIProvider } from "./ui";
import { AuthProvider } from "./auth";

import "quill/dist/quill.core.css";
import "animate.css";

function App() {
  return (
    <>
      <Global
        styles={css`
          :root {
            --space-xxs: ${2 / 16}rem;
            --space-xs: ${5 / 16}rem;
            --space-sm: ${15 / 16}rem;
            --space-md: ${25 / 16}rem;
            --space-lg: ${45 / 16}rem;
            --space-xl: ${80 / 16}rem;
            --space-xxl: ${140 / 16}rem;
            --space-xxxl: ${220 / 16}rem;
            --border-radius-xs: ${2 / 16}rem;
            --border-radius-sm: ${5 / 16}rem;
            --border-radius-md: ${10 / 16}rem;
            --border-radius-lg: ${20 / 16}rem;
            --border-radius-xl: ${50 / 16}rem;
            --breakpoints-xs: ${356 / 16}rem;
            --breakpoints-sm: ${576 / 16}rem;
            --breakpoints-md: ${768 / 16}rem;
            --breakpoints-lg: ${1050 / 16}rem;
            --breakpoints-xl: ${1400 / 16}rem;
            --breakpoints-px-xs: 356px;
            --breakpoints-px-sm: 576px;
            --breakpoints-px-md: 768px;
            --breakpoints-px-lg: 1050px;
            --breakpoints-px-xl: 1400px;
          }

          #__next {
            height: 100%;
          }

          ${styleReset}
        `}
      />
      <UIProvider breakpoints={breakpoints}>
        <AlertProvider>
          <BrowserRouter>
            <AuthProvider>
              <Main>
                <Routes />
              </Main>
            </AuthProvider>
          </BrowserRouter>
        </AlertProvider>
      </UIProvider>
    </>
  );
}

export const Main = ({ children }: { children: any }) => {
  useEffect(() => {
    const html = document.getElementsByTagName("html");

    if (html.length > 0) {
      html[0].setAttribute("lang", "es");
    }
  }, []);

  return <>{children}</>;
};

export default App;
