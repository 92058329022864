import { css } from "@emotion/react";

import * as ui from "../../../ui";

export const article = css`
  background-color: #ededed;
  border-radius: ${ui.rem("xs")};
  width: 100%;
  padding: ${ui.rem("md")};
`;

export const p = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(18)};
  line-height: ${ui.rem(20)};
  color: #000000;

  @media (min-width: ${ui.breakpoints.lg}px) {
    font-size: ${ui.rem(25)};
    line-height: ${ui.rem(30)};
  }
`;
