import { css } from "@emotion/react";

import * as ui from "../..";

export const container = css``;

export const section = css`
  width: 100%;
  ${ui.column("md")};
  min-height: calc(100vh - ${ui.rem(100)});
  padding-bottom: ${ui.rem("lg")};
`;

export const header = css`
  ${ui.row("md")}
  background-color: #EDEDED;
  width: 100%;
`;

export const filterContainer = css`
  ${ui.column("xs")}

  @media (min-width: ${ui.breakpoints.sm}px ) {
    ${ui.row("md")}
  }
`;

export const filterInputContainer = (isLastOne: boolean) => css`
  ${ui.row("xs")}

  @media (min-width: ${ui.breakpoints.sm}px ) {
    ${isLastOne ? "margin-left: auto;" : ""}
  }
`;

export const filterLabel = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(30)};
  color: #000000;
`;

export const filterInput = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(30)};
  color: #000000;
  border-radius: ${ui.rem("xs")};
  padding: 0px ${ui.rem("sm")};

  :focus,
  :focus-within,
  :focus-visible,
  :visited {
    outline: none;
  }

  @media (max-width: ${ui.breakpoints.sm - 1}px) {
    width: 100%;
  }
`;

export const filterTitle = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: ${ui.rem(20)};
  line-height: ${ui.rem(30)};
  color: #545454;
`;

export const optionsButtons = css`
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  padding: ${ui.rem("xs")} ${ui.rem("xs")};
  border-radius: ${ui.rem("xs")};
`;

export const addButton = css`
  background-color: white;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  border-radius: var(--space-xs);
  font-size: ${ui.rem(14)};
  cursor: pointer;
  transition: background-color 300ms;

  :hover {
    background-color: #d7d7d7;
  }
`;

export const list = css`
  width: 100%;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(100%, ${500 / 16}rem), 1fr)
  );
  ${ui.gridRepeat("sm")};
`;

export const listContainer = css`
  padding-top: 0px;
  height: 100%;
`;

export const loadingContainer = css`
  ${ui.column("sm")};
  width: 100%;
`;

export const element = css`
  width: 100%;
`;

export const footer = css`
  align-self: flex-end;
  margin-top: auto;
`;

export const footerContainer = css`
  ${ui.row("xs")};
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const footerButton = (active: boolean) => css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: ${ui.rem(16)};
  line-height: ${ui.rem(22)};
  text-align: center;
  color: #545454;
  background-color: white;
  transition: box-shadow 300ms;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  border: 1px solid #000000;

  ${active
    ? `
  cursor:pointer;
  :hover {
      box-shadow: 1px ${ui.rem(2)} ${ui.rem(2)} #a1a1a1;
  }

  `
    : "opacity: 0.2;"}
`;

export const footerPage = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: ${ui.rem(16)};
  line-height: ${ui.rem(22)};
  text-align: center;
  color: #ec6036;

  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  border: 1px solid #000000;
`;
