/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { services } from "../../../services";
import { EditComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const NewsCreate = () => {
  const navigate = useNavigate();

  const inputs = [
    {
      type: "subtitle",
      label: "Español",
    },
    {
      type: "text",
      placeholder: "Escribir título",
      label: "Título",
      name: "title-spanish",
    },
    {
      type: "text",
      placeholder: "Escribir categoría",
      label: "Categoría",
      name: "category-spanish",
    },
    {
      type: "space",
      value: "sm",
    },
    {
      type: "subtitle",
      label: "Ingles",
    },
    {
      type: "text",
      placeholder: "Escribir título",
      label: "Título",
      name: "title-english",
    },
    {
      type: "text",
      placeholder: "Escribir categoría",
      label: "Categoría",
      name: "category-english",
    },
    {
      type: "space",
      value: "sm",
    },
    {
      type: "subtitle",
      label: "Más información",
    },
    {
      type: "text",
      placeholder: "Escribir enlance",
      label: "Enlace",
      name: "url",
    },
    {
      type: "file",
      placeholder: "Seleccionar imagen",
      label: "Imagen",
      name: "image",
    },
  ];

  const initForm = {
    "title-spanish": "",
    "category-spanish": "",
    "title-english": "",
    "category-english": "",
    url: "",
    image: "",
  };

  const navigateSuccess = async () => {
    navigate("/news", { replace: true });
  };

  const onReturn = async () => {
    navigate(-1);
  };

  return (
    <ScaffoldMain root="news" title="Crear noticia" onReturn={onReturn}>
      <EditComponent
        initForm={initForm}
        inputs={inputs}
        service={services.newsCreate}
        navigateSuccess={navigateSuccess}
      />
    </ScaffoldMain>
  );
};
