import _ from "lodash";
import { useEffect, useState } from "react";

export const useScreenSize = (ref?: any) => {
  if (typeof window === "undefined") {
    return {
      width: 0,
      height: 0,
      resizing: false,
      resizeContainer: () => "",
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [width, setWidth] = useState(window.innerWidth);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [height, setHeight] = useState(window.innerHeight);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [resizing, setResizing] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const debounceHandleResize = _.debounce(function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setResizing(true);

      setTimeout(() => {
        setResizing(false);
      }, 100);
    }, 100);

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  });

  return {
    width,
    height,
    resizing,
  };
};

export const useResizeElement = (
  ref: any,
  breakpoints: { size: number; className: string }[]
) => {
  const [className, setclassName] = useState("");
  const { width } = useScreenSize();

  useEffect(() => {
    const _className = resizeContainer();

    setclassName(_className);
  }, [width]);

  const resizeContainer = (): string => {
    if (ref?.current) {
      const width = ref?.current?.clientWidth;

      if (!width) return "";

      let classSelected = "";
      breakpoints
        .sort((value) => value.size)
        .map((value) => {
          if (width >= value.size && value.className) {
            classSelected = value.className;
          }
        });

      return classSelected;
    }

    return "";
  };

  return {
    className,
  };
};
