/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import { services } from "../../../services";
import { Card, ListComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const UsersList = () => {
  const navigate = useNavigate();

  const onNavigate = (id: string) => {
    navigate(`/users/${id}`);
  };

  return (
    <ScaffoldMain root="users" title="Usuarios" navigateCreate="/users/create">
      <ListComponent
        root="/users"
        messageEmpty={"Sin usuarios"}
        service={services.usersList}
        child={(doc: any) => {
          return (
            <Card
              title={doc.name}
              description={doc.email}
              onClick={() => onNavigate(doc._id)}
            />
          );
        }}
      />
    </ScaffoldMain>
  );
};
