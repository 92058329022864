import { Route } from "react-router-dom";
import { UsersCreate, UsersEdit, UsersList } from "../views";

export const RouterUsers = () => {
  return (
    <Route path="users">
      <Route index element={<UsersList />} />
      <Route path="create" element={<UsersCreate />} />
      <Route path=":id" element={<UsersEdit />} />
    </Route>
  );
};
