/** @jsxImportSource @emotion/react */
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";

import * as styles from "./styles";

export interface InputFileProps {
  form: any;
  setForm: React.Dispatch<any>;
  onChange: (event: any) => void;
  label: string;
  name: string;
  type: string;
}

export const InputFile = ({
  form,
  setForm,
  onChange,
  label,
  name,
  type,
  ...props
}: InputFileProps) => {
  const inputFile = useRef<any>(null);

  const onDeleteImage = (event: any) => {
    const target = event?.target;
    const name = target?.name;
    setForm((state: any) => {
      return {
        ...state,
        [name]: null,
      };
    });
  };

  const onSelectImage = (event: any) => {
    inputFile.current.click();
  };

  const onClick = (event: any) => {
    event.target.value = null;
  };

  return (
    <div css={styles.inputContainer}>
      <label css={styles.inputLabel}>{label}</label>
      {form[name] ? (
        <div
          onClick={() =>
            onDeleteImage({
              target: {
                name,
              },
            })
          }
          css={styles.inputFileImageContainer}
        >
          <div css={styles.trashContainer}>
            <FontAwesomeIcon
              css={styles.trash}
              icon={faTrash}
              onClick={onDeleteImage}
            />
          </div>
          <img
            src={
              typeof form[name] === "string"
                ? form[name]
                : URL.createObjectURL(form[name])
            }
            css={styles.inputFileImage}
            alt="MCNO file"
          />
        </div>
      ) : (
        <button onClick={onSelectImage} css={styles.inputFileAdd}>
          Agregar imagen
        </button>
      )}
      <div></div>
      <input
        ref={inputFile}
        css={styles.inputInputFile}
        name={name}
        type={type}
        onClick={onClick}
        onChange={onChange}
      />
    </div>
  );
};
