/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { services } from "../../../services";
import { EditComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const UsersCreate = () => {
  const navigate = useNavigate();

  const inputs = [
    {
      type: "text",
      placeholder: "Escribir nombre",
      label: "Nombre",
      name: "name",
    },
    {
      type: "email",
      placeholder: "Escribir email",
      label: "Email",
      name: "email",
    },
    {
      type: "checkbox",
      label: "Permisos",
      elements: [
        {
          name: "permissions.users",
          label: "Usuarios",
        },
        {
          name: "permissions.news",
          label: "Noticias",
        },
        {
          name: "permissions.blogs",
          label: "Blog",
        },
        {
          name: "permissions.prospects",
          label: "Prospectos",
        },
      ],
    },
  ];

  const initForm = {
    name: "",
    email: "",
    "permissions.news": false,
    "permissions.blogs": false,
    "permissions.prospects": false,
    "permissions.users": false,
  };

  const navigateSuccess = async () => {
    navigate("/users", { replace: true });
  };

  const onReturn = async () => {
    navigate(-1);
  };

  return (
    <ScaffoldMain root="users" title="Crear usuario" onReturn={onReturn}>
      <EditComponent
        initForm={initForm}
        inputs={inputs}
        service={services.usersCreate}
        navigateSuccess={navigateSuccess}
      />
    </ScaffoldMain>
  );
};
