import { css } from "@emotion/react";

import * as ui from "../../../../../ui";

export const inputContainer = css`
  ${ui.column("xs")};
`;

export const inputLabel = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(14)};
  color: #000000;
`;

export const inputInput = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(30)};
  color: #000000;
  padding: 0px;
  border-bottom: 1px solid #000000;

  :focus,
  :focus-within,
  :focus-visible,
  :visited {
    outline: none;
  }
`;
