/** @jsxImportSource @emotion/react */
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, useAuthListener } from "../../../auth";
import { useAlertListener } from "../alert";
import { Appbar } from "../appbar";
import { SidebarProvider, Sidebar } from "../sidebar";

import * as styles from "./styles";

export interface ScaffoldMainProps {
  title: string;
  root: "blog" | "news" | "users" | "prospects" | "dashboard";
  children: JSX.Element | JSX.Element[];
  onReturn?: () => void;
  navigateCreate?: string;
  destroy?: {
    serviceDestroy: (payload: any) => any;
    navigateDestroy: (body: any) => Promise<any>;
    id: string;
  };
}

export const ScaffoldMain = ({
  title,
  onReturn,
  destroy,
  children,
  navigateCreate,
  root,
}: ScaffoldMainProps) => {
  const serviceDestroy = destroy?.serviceDestroy;
  const navigateDestroy = destroy?.navigateDestroy;
  const id = destroy?.id;

  const navigate = useNavigate();
  const { addAlert } = useAlertListener();
  const [loading, setLoading] = useState(false);

  const { auth } = useAuthListener();

  useEffect(() => {
    if (auth === "w") return;
    if (!auth) {
      return navigate("/login");
    }
    if (!(auth as Auth) && root === "dashboard") {
      return navigate("/login");
    }
    if (!(auth as Auth)?.permissions?.blogs && root === "blog") {
      return navigate("/");
    }
    if (!(auth as Auth)?.permissions?.news && root === "news") {
      return navigate("/");
    }
    if (!(auth as Auth)?.permissions?.prospects && root === "prospects") {
      return navigate("/");
    }
    if (!(auth as Auth)?.permissions?.users && root === "users") {
      return navigate("/");
    }
  }, [auth]);

  const onDestroy = async () => {
    if (!navigateDestroy || !id || !serviceDestroy) return;

    setLoading(true);
    document.body.style.cursor = "wait";
    try {
      const resp = await serviceDestroy(id);
      const data = resp.data;

      if (data.status) {
        addAlert({ title: data.message, type: "success" });
        navigateDestroy(data.body);
      } else {
        addAlert({ title: data.message, type: "alert" });
      }
      setLoading(false);
      document.body.style.cursor = "default";
    } catch (error) {
      document.body.style.cursor = "default";
      setLoading(false);
      addAlert({
        title: "Hubo un problema, intentarlo más tarde.",
        type: "alert",
      });
    }
  };

  const _navigateCreate = async () => {
    if (navigateCreate) {
      navigate(navigateCreate);
    }
  };

  return (
    <SidebarProvider>
      <div css={styles.container}>
        <Sidebar />
        <main css={styles.main}>
          <Appbar
            title={title}
            onReturn={onReturn}
            actions={[
              ...(navigateCreate
                ? [
                    {
                      icon: faPlus,
                      action: _navigateCreate,
                    },
                  ]
                : []),
              ...(navigateDestroy && id && serviceDestroy
                ? [{ action: onDestroy, icon: faTrash }]
                : []),
            ]}
          />
          <div css={styles.body}>{children}</div>
        </main>
      </div>
    </SidebarProvider>
  );
};
