/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { services } from "../../services";

import { Container, ScaffoldMain } from "../../ui";

import * as styles from "./styles";

export const Dashboard = ({ css, className }: any) => {
  const [categories, setCategory] = useState<any>(null);

  useEffect(() => {
    getInit();
  }, []);

  const getInit = async () => {
    const resp = await services.dashboardMain();

    if (resp?.data) {
      setCategory(resp?.data?.body);
    }
  };

  return (
    <ScaffoldMain root="dashboard" title="Dashboard">
      <Container>
        {categories && (
          <div css={styles.container}>
            {categories.map((category: any, index: number) => {
              return (
                <div key={index} css={styles.categoryContainer}>
                  <h2 css={styles.categoryTitle}>{category.title}</h2>
                  <div css={styles.grid}>
                    {category.elements.map((stadistic: any, index: number) => {
                      return (
                        <div key={index} css={styles.card}>
                          <p css={styles.content}>{stadistic.content}</p>
                          <h3 css={styles.title}>{stadistic.title}</h3>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </ScaffoldMain>
  );
};
