import { css } from "@emotion/react";

import * as ui from "../../../ui";

export type OpenTypes = "opening" | "open" | "closing" | "close" | "close-full";

export const sidebar = (open: OpenTypes) => css`
  ${ui.column("lg")};
  align-items: center;
  padding: ${ui.rem("lg")} ${ui.rem("sm")};
  width: 90%;
  height: 100vh;
  max-width: ${ui.rem(350)};
  background: #272727;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: ${ui.breakpoints.lg - 1}px) {
    ${open === "close-full" ? "display: none;" : ""}
  }

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: ${ui.rem(250)};
    min-width: ${ui.rem(250)};

    &.animate__animated {
      position: fixed !important;
    }

    &.animate__fadeInLeft,
    .animate__fadeOutLeft {
      animation: none;
    }
  }
`;

export const imageContainer = css`
  height: ${ui.rem(25)};
  margin-bottom: ${ui.rem("md")};
`;

export const image = css`
  height: 100%;
`;

export const link = (active: boolean) => css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(18)};
  line-height: ${ui.rem(22)};
  color: ${active ? `#EC6036` : `#ffffff`};
  cursor: pointer;
  transition: font-weight 300ms, font-size 300ms;

  :hover {
    font-weight: 500;
    font-size: ${ui.rem(19)};
  }
`;

export const closeButton = css`
  ${link(false)};
  ${ui.row("sm")};
  ${ui.flexCenter()};
  margin-top: auto;
  cursor: pointer;
`;

export const layer = (open: OpenTypes) => {
  let opacity: number = 0;

  if (open === "opening") {
    opacity = 0;
  } else if (open === "open") {
    opacity = 1;
  } else if (open === "closing") {
    opacity = 1;
  } else if (open === "close") {
    opacity = 0;
  } else if (open === "close-full") {
    opacity = 0;
  }

  return css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #22222269;
    z-index: 2;
    transition: opacity 600ms;

    @media (min-width: ${ui.breakpoints.lg}px) {
      display: none;
    }

    opacity: ${opacity};
  `;
};
