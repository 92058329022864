import { ThemeSizesTypes } from "../types";

const portion = 1;
export const rem = (size: number | ThemeSizesTypes) => {
  let _size: number;

  if (typeof size === "string") {
    return `var(--space-${size})`;
  } else {
    _size = size;
  }

  return `${(_size * portion) / 16}rem`;
};

export const em = (size: number | ThemeSizesTypes) => {
  let _size: number;

  if (typeof size === "string") {
    return `var(--space-${size})`;
  } else {
    _size = size;
  }

  return `${(_size * portion) / 16}em`;
};
