/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import { services } from "../../../services";
import { Card, ListComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const NewsList = () => {
  const navigate = useNavigate();

  const onNavigate = (id: string) => {
    navigate(`/news/${id}`);
  };

  return (
    <ScaffoldMain root="news" title="Noticias" navigateCreate="/news/create">
      <ListComponent
        root="/news"
        messageEmpty="Sin noticias"
        service={services.newsList}
        child={(doc: any) => {
          return (
            <Card
              title={doc["title-spanish"] || doc["title-english"]}
              src={services.newsImageDownload(doc.image)}
              onClick={() => onNavigate(doc._id)}
            />
          );
        }}
      />
    </ScaffoldMain>
  );
};
