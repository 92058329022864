/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";

import { rem, row } from "../..";

import * as ui from "../..";

export interface ContainerProps extends React.ComponentProps<"div"> {
  children: React.ReactElement | React.ReactElement[];
  size?: "sm" | "md" | "lg";
}

const _Component = (
  { children, className, size = "md", ...props }: ContainerProps,
  ref: any
) => {
  return (
    <div
      ref={ref}
      className={`container ${className}`}
      css={css`
        width: 100%;
        ${size === "sm"
          ? "max-width: 1000px;"
          : size === "lg"
          ? "max-width: 1600px;"
          : "max-width: 1400px;"}
        margin: 0px auto;

        padding: ${rem("sm")} ${rem("md")};

        @media (min-width: ${ui.breakpoints.lg}px) {
          padding-left: ${rem("xl")};
          padding-right: ${rem("xl")};
        }

        ${size === "sm" &&
        `
          @media (min-width: ${ui.breakpoints.lg}px) {
            padding-left: ${rem("md")};
            padding-right: ${rem("md")};
          }
  
          `}

        ${row("xl")}
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export const Container = React.forwardRef(_Component);
