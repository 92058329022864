import axios from "axios";
import { environments } from "../enviroments";

// LOGIN
export interface LoginServiceProps {
  email: string;
  password: string;
}

export const login = async (payload: LoginServiceProps) => {
  return axios.post(`${environments.API}/login`, payload, {
    withCredentials: true,
  });
};

export const logout = async () => {
  return axios.get(`${environments.API}/logout`, { withCredentials: true });
};

// RECOVER
export interface RecoverServiceProps {
  email: string;
}

export const recover = async (payload: RecoverServiceProps) => {
  return axios.post(`${environments.API}/recover`, payload, {
    withCredentials: true,
  });
};

// CONFIRM-ACCOUNT
export interface ConfirmAccountServiceProps {
  password: string;
  passwordConfirm: string;
}

export const confirmAccount = async (
  token: string,
  payload: ConfirmAccountServiceProps
) => {
  return axios.post(`${environments.API}/confirm-account`, payload, {
    headers: {
      "x-token": token,
    },
    withCredentials: true,
  });
};

// CHANGE-PASSOWRD
export interface ChangePasswordServiceProps {
  password: string;
  passwordConfirm: string;
}

export const changePassword = async (
  token: string,
  payload: ChangePasswordServiceProps
) => {
  return axios.post(`${environments.API}/change-password`, payload, {
    headers: {
      "x-token": token,
    },
    withCredentials: true,
  });
};

// SESSION
export const session = async () => {
  return axios.get(`${environments.API}/session`, { withCredentials: true });
};
