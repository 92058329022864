import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { useScreenSize } from "../hooks";

// Name reference:
// UI

// Context
const _Context = createContext<ContextLogic | null>(null);

type MenuOpenType = "open" | "close" | "pre";

type MenusSelectedTypes = "/" | "#master-plan" | "#ubicacion" | "#contacto";

// Logic
interface ContextLogic {
  resizing?: boolean;
  menuOpen: MenuOpenType;
  setMenuOpen: React.Dispatch<React.SetStateAction<MenuOpenType>>;
  onOpenMenu: () => void;
  onCloseMenu: () => void;
  isDark: boolean;
  menuPressed: boolean;
  setIsDark: React.Dispatch<React.SetStateAction<boolean>>;
  menuSelected: MenusSelectedTypes;
  setMenuSelected: React.Dispatch<React.SetStateAction<MenusSelectedTypes>>;
  onScroll: number;
  onResize: number;
  size: number | null;
}

export interface UIBreakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const useUILogic = ({ breakpoints }: { breakpoints: UIBreakpoints }) => {
  const { width, resizing } = useScreenSize();
  const [size, setSize] = useState<number | null>(null);
  const [isDark, setIsDark] = useState(false);
  const [menuOpen, setMenuOpen] = useState<MenuOpenType>("pre");
  const [menuPressed, SetMenuPressed] = useState<boolean>(false);
  const [menuSelected, setMenuSelected] = useState<MenusSelectedTypes>("/");
  const [onScroll, setOnScroll] = useState<number>(0);
  const [onResize, setOnResize] = useState<number>(0);

  const onOpenMenu = () => {
    setMenuOpen("open");
    SetMenuPressed(true);
  };

  const onCloseMenu = () => {
    setMenuOpen("close");
    setTimeout(() => {
      setMenuOpen("pre");
    }, 700);
  };

  useLayoutEffect(() => {
    if (width >= breakpoints.xl) {
      setSize(5);
    } else if (width >= breakpoints.lg) {
      setSize(4);
    } else if (width >= breakpoints.md) {
      setSize(3);
    } else {
      setSize(2);
    }
  }, [width]);

  useEffect(() => {
    setMenuOpen("pre");
  }, [width]);

  useEffect(() => {
    const rootElement = document?.getElementById("root");

    if (rootElement) {
      rootElement.removeAttribute("data-theme");

      if (isDark) {
        rootElement.setAttribute("data-theme", "dark");
      } else {
        rootElement.setAttribute("data-theme", "light");
      }
    }
  }, [isDark]);

  useEffect(() => {
    const rootElement = document?.getElementById("root");

    if (rootElement) {
      rootElement.removeAttribute("data-size");

      if (size && size >= 4) {
        rootElement.setAttribute("data-size", "desktop");
      } else {
        rootElement.setAttribute("data-size", "mobile");
      }
    }
  }, [width]);

  useEffect(() => {
    const rootElement = document?.getElementsByTagName("body")[0];

    if (rootElement) {
      rootElement.removeAttribute("data-menu-open");

      if (menuOpen) {
        rootElement.setAttribute("data-menu-open", "true");
      } else {
        rootElement.setAttribute("data-menu-open", "false");
      }
    }
  }, [menuOpen]);

  const onScrollChange = () => {
    setOnScroll((state) => {
      if (state >= 1000) {
        return 0;
      } else {
        return state + 1;
      }
    });
  };

  const onResizeChange = () => {
    setOnResize((state) => {
      if (state >= 1000) {
        return 0;
      } else {
        return state + 1;
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollChange);
    window.addEventListener("resize", onResizeChange);
  }, []);

  return {
    resizing,
    isDark,
    setIsDark,
    menuOpen,
    setMenuOpen,
    onOpenMenu,
    onCloseMenu,
    menuPressed,
    size,
    onScroll,
    onResize,
    menuSelected,
    setMenuSelected,
  };
};

// Provider
export const UIProvider = ({
  children,
  breakpoints,
}: {
  children: any;
  breakpoints: UIBreakpoints;
}) => {
  const logic = useUILogic({ breakpoints });

  return <_Context.Provider value={logic}>{children}</_Context.Provider>;
};

// Listener
export const useUIListener = () => {
  return useContext(_Context) as ContextLogic;
};
