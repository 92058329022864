import { css } from "@emotion/react";

import * as ui from "../../../ui";

export const container = css`
  ${ui.row(0)};
`;

export const main = css`
  ${ui.column(0)};
  width: 100%;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: calc(100% - ${ui.rem(250)});
    margin-left: ${ui.rem(250)};
  }
`;

export const body = css`
  margin-top: ${ui.rem(100)};
`;
