import { rem } from "../utils";
import { isSizeType, ThemeSizesTypes } from "../types";

export const gridRepeat = (value: number | ThemeSizesTypes) => {
  let space = "";
  // let spaceRow = "";

  if (isSizeType(value)) {
    space = `var(--space-${value})`;
  } else {
    space = rem(value);
  }

  // if (isSizeType(value)) {
  //   spaceRow = `var(--space-${getSizeTypeBefore(value as ThemeSizesTypes)})`;
  // } else {
  //   spaceRow = rem((value as number) / 3);
  // }

  return `
        display: grid;
        grid-gap: ${space};
        grid-row-gap: calc( ${space});
        grid-column-gap: ${space};
        width: 100%;
        
        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            margin-right: -${space} !important;
        
            > * {
                margin-right: ${space};
                margin-bottom: ${space};
            }
        }
        
        @supports (not (gap: 0px)) {
            margin-right: -${space} !important;
        
            > * {
                margin-right: ${space};
                margin-bottom: ${space};
            }
        }
    `;
};
