import axios from "axios";
import { environments } from "../enviroments";
import { utilsRoutes } from "./_utils";

// PROSPECTS LIST
export interface ProspectsListServiceProps {
  page: number;
  limit: number;
}

export const prospectsList = async (payload: ProspectsListServiceProps) => {
  const params = utilsRoutes.addParams(payload);
  return axios.get(`${environments.API}/prospects${params}`, {
    withCredentials: true,
  });
};

// PROSPECTS SHOW
export const prospectsShow = async (id: string) => {
  return axios.get(`${environments.API}/prospects/${id}`, {
    withCredentials: true,
  });
};

// PROSPECTS UPDATE
export interface ProspectsUpdateServiceProps {
  name: string;
  phone: string;
  email: string;
  contacted: boolean;
}

export const prospectsUpdate = async (
  id: string,
  payload: ProspectsUpdateServiceProps
) => {
  return axios.put(`${environments.API}/prospects/${id}`, payload, {
    withCredentials: true,
  });
};

// PROSPECTS DELETE
export const prospectsDestroy = async (id: string) => {
  return axios.delete(`${environments.API}/prospects/${id}`, {
    withCredentials: true,
  });
};
