import { css } from "@emotion/react";

import { gridRepeat } from "../../ui";
import * as ui from "../../ui";

export const container = css`
  ${ui.column("lg")};
  padding-top: ${ui.rem("md")};
  width: 100%;
`;

export const categoryContainer = css`
  ${ui.column("sm")};
  width: 100%;
`;

export const categoryTitle = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(38)};
  line-height: ${ui.rem(38)};
  color: s #000000;
`;

export const grid = css`
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(100%, ${300 / 16}rem), 1fr)
  );

  ${gridRepeat("md")};
`;

export const card = css`
  padding: ${ui.rem("lg")} ${ui.rem("xl")};
  ${ui.column("xs")};
  background-color: #ededed;
  color: #000000;
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(22)};
  line-height: ${ui.rem(22)};
  color: #000000;
`;

export const content = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(40)};
  line-height: ${ui.rem(40)};
  color: #545454;
`;
