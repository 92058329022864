import { Navigate, Route, Routes as RoutesDom } from "react-router-dom";

import { RouterAuth } from "./auth";
import { RouterBlog } from "./blog";
import { RouterDashboard } from "./dashboard";
import { RouterNews } from "./news";
import { RouterProspects } from "./prospects";
import { RouterUsers } from "./users";

export const Routes = () => {
  return (
    <>
      <RoutesDom>
        {RouterAuth()}
        {RouterDashboard()}
        {RouterUsers()}
        {RouterNews()}
        {RouterProspects()}
        {RouterBlog()}

        <Route path="*" element={<Navigate to="/" replace />} />
      </RoutesDom>
    </>
  );
};
