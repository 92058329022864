/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";

import { useSidebarListener } from "../sidebar/provider";
import * as styles from "./styles";
import { Container } from "../container";

export interface AppbarProps {
  title: string;
  onReturn?: () => void;
  actions?: { action: () => void; icon: any }[];
}

export const Appbar = ({ title, onReturn, actions, ...props }: AppbarProps) => {
  const { onOpen } = useSidebarListener();

  return (
    <header css={styles.appbar}>
      <Container css={styles.container}>
        <>
          {onReturn && (
            <button onClick={onReturn} css={styles.buttonIcon}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          )}
          <h1 css={styles.title}>{title}</h1>
          <div css={styles.actionsContainer}>
            {actions &&
              actions.map((action, index) => {
                return (
                  <button
                    key={index}
                    onClick={action.action}
                    css={styles.buttonIcon}
                  >
                    <FontAwesomeIcon icon={action.icon} />
                  </button>
                );
              })}
            <button onClick={onOpen} css={styles.menu}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </>
      </Container>
    </header>
  );
};
