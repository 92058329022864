/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import { services } from "../../../services";
import { Card, ListComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const BlogList = () => {
  const navigate = useNavigate();

  const onNavigate = (id: string) => {
    navigate(`/blog/${id}`);
  };

  return (
    <ScaffoldMain root="blog" title="Blog" navigateCreate="/blog/create">
      <ListComponent
        root="/blog"
        messageEmpty="Sin blogs"
        service={services.blogList}
        child={(doc: any) => {
          return (
            <Card
              title={doc["title-spanish"] || doc["title-english"]}
              description={doc["subtitle-spanish"] || doc["subtitle-english"]}
              src={services.blogImageDownload(doc.image)}
              onClick={() => onNavigate(doc._id)}
            />
          );
        }}
      />
    </ScaffoldMain>
  );
};
