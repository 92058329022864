/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { services } from "../../../services";
import { EditComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const BlogCreate = () => {
  const navigate = useNavigate();

  const inputs = [
    {
      type: "subtitle",
      label: "Español",
    },
    {
      type: "text",
      placeholder: "Escribir título",
      label: "Título",
      name: "title-spanish",
    },
    {
      type: "text",
      placeholder: "Escribir subtítulo",
      label: "Subtítulo",
      name: "subtitle-spanish",
    },
    {
      type: "textarea-advance",
      placeholder: "Escribir contenido",
      label: "Contenido",
      name: "content-spanish",
    },
    {
      type: "space",
      value: "sm",
    },
    {
      type: "subtitle",
      label: "Ingles",
    },
    {
      type: "text",
      placeholder: "Escribir título",
      label: "Título",
      name: "title-english",
    },
    {
      type: "text",
      placeholder: "Escribir subtítulo",
      label: "Subtítulo",
      name: "subtitle-english",
    },
    {
      type: "textarea-advance",
      placeholder: "Escribir contenido",
      label: "Contenido",
      name: "content-english",
    },
    {
      type: "space",
      value: "sm",
    },
    {
      type: "subtitle",
      label: "Más información",
    },
    {
      type: "text",
      placeholder: "Escribir autor",
      label: "Autor",
      name: "createdBy",
    },
    {
      type: "file",
      placeholder: "Seleccionar imagen",
      label: "Imagen",
      name: "image",
    },
  ];

  const initForm = {
    "title-spanish": "",
    "subtitle-spanish": "",
    "content-spanish": "",
    "subtitle-english": "",
    "title-english": "",
    "content-english": "",
    createdBy: "",
    image: "",
  };

  const navigateSuccess = async () => {
    navigate("/blog", { replace: true });
  };

  const onReturn = async () => {
    navigate(-1);
  };

  return (
    <ScaffoldMain root="blog" title="Crear blog" onReturn={onReturn}>
      <EditComponent
        initForm={initForm}
        inputs={inputs}
        service={services.blogCreate}
        navigateSuccess={navigateSuccess}
      />
    </ScaffoldMain>
  );
};
