import { createContext, useContext, useState } from "react";

import * as styles from "./styles";

interface SidebarContextProps {
  onClose: () => void;
  onOpen: () => void;
  open: styles.OpenTypes;
}

export const SidebarContext = createContext<SidebarContextProps>({
  onClose: () => null,
  onOpen: () => null,
  open: "close-full",
});

export interface SidebarProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [open, setIsOpen] = useState<styles.OpenTypes>("close-full");

  const onClose = () => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("style", ``);

    setIsOpen("closing");

    setTimeout(() => {
      setIsOpen("close");
    }, 100);

    setTimeout(() => {
      setIsOpen("close-full");
    }, 700);
  };

  const onOpen = () => {
    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("style", `overflow:hidden;`);

    setIsOpen("opening");

    setTimeout(() => {
      setIsOpen("open");
    }, 100);
  };

  return (
    <SidebarContext.Provider value={{ onClose, onOpen, open }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarListener = () => {
  return useContext(SidebarContext);
};
