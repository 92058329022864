import { css } from "@emotion/react";

import * as ui from "../..";

export const article = (hasOnClick: boolean) => css`
  ${ui.flexResponse("sm")};
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  padding: ${ui.rem("md")};
  transition: box-shadow 300ms;
  align-items: center;

  ${hasOnClick
    ? `
        cursor: pointer;
        
        :hover {
            box-shadow: 1px ${ui.rem(2)} ${ui.rem(2)} #a1a1a1;
        }
    `
    : ``}
`;

export const texts = css`
  ${ui.column("xs")};
  align-items: flex-start;
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(24)};
  line-height: ${ui.rem(24)};
  color: #000000;
`;

export const description = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(16)};
  line-height: ${ui.rem(16)};
  color: #000000;
`;

export const button = css`
  ${ui.row("xs")};
  ${ui.flexCenter()};
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(20)};
  color: #ec6036;
  background-color: transparent;
  margin-top: auto;

  svg {
    color: #ec6036;
  }
`;

export const imageContainer = css`
  width: ${ui.rem(150)};
  height: ${ui.rem(100)};
`;

export const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const iconChecked = css`
  margin-left: auto;
  color: green;
  font-size: ${ui.rem(30)};
`;
