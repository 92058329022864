/** @jsxImportSource @emotion/react */
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { useSidebarListener } from "./provider";
import * as styles from "./styles";
import { services } from "../../../services";
import { Auth, useAuthListener } from "../../../auth";

export interface SidebarProps {}

export const Sidebar = ({ ...props }: SidebarProps) => {
  const navigate = useNavigate();
  const router = useLocation();
  const { auth: _auth, setAuth } = useAuthListener();
  const { open, onClose } = useSidebarListener();

  const auth = _auth as Auth;

  let routes: { name: string; href: string }[] = [];

  if (_auth && _auth !== "w")
    routes.push({
      name: "Dashboard",
      href: "/",
    });
  if (auth?.permissions?.prospects)
    routes.push({
      name: "Prospectos",
      href: "/prospects",
    });
  if (auth?.permissions?.news)
    routes.push({
      name: "Noticias",
      href: "/news",
    });
  if (auth?.permissions?.blogs)
    routes.push({
      name: "Blog",
      href: "/blog",
    });
  if (auth?.permissions?.users)
    routes.push({
      name: "Users",
      href: "/users",
    });

  const isActive = (href: string): boolean => {
    if (href === "/") {
      if (router.pathname === "/") {
        return true;
      }
      return false;
    } else {
      return router.pathname.includes(href);
    }
  };

  const onExit = async () => {
    setAuth(undefined);
    await services.logout();
    navigate("/login");
  };

  return (
    <>
      <aside
        css={styles.sidebar(open)}
        className={`animate__animated ${
          open === "open" || open === "opening"
            ? "animate__fadeInLeft"
            : open === "closing" || open === "close"
            ? "animate__fadeOutLeft"
            : ""
        }`}
      >
        <div css={styles.imageContainer}>
          <img src="../branding/logo.png" alt="MCNO logo" css={styles.image} />
        </div>
        {routes.map((route, index) => {
          const active = isActive(route.href);

          return (
            <Link key={index} to={route.href} css={styles.link(active)}>
              {route.name}
            </Link>
          );
        })}
        <div css={styles.closeButton} onClick={onExit}>
          Cerrar sesión
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </aside>
      {open !== "close-full" && (
        <div onClick={onClose} css={styles.layer(open)} />
      )}
    </>
  );
};
