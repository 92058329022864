import { css } from "@emotion/react";

import * as ui from "../..";

export const styleBar = css`
  & {
    width: 100%;
    height: ${ui.rem(50)};
    display: block;
    background: #dfdfdf;
    margin-bottom: ${ui.rem(10)};
    border-radius: ${ui.rem("xxs")};

    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      #dfdfdf;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
  }

  @keyframes shine {
    to {
      background-position: 120% 0;
    }
  }
`;

export const styleCircle = css`
  & {
    width: 50px;
    height: 50px;
    display: block;
    background: lightgray;
    margin-bottom: 10px;
    border-radius: 200px;

    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      lightgray;
    background-repeat: repeat-y;
    background-size: 20px 50px;
    background-position: 0 0;
    animation: loadingCircleAnimation 1s infinite;
  }

  @keyframes loadingCircleAnimation {
    to {
      background-position: 150% 0;
    }
  }
`;
