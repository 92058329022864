import { useNavigate, Link } from "react-router-dom";

import { useAuthListener } from "../../../auth";
import { services } from "../../../services";
import { ScaffoldAuth } from "../../../ui";

export const AuthLogin = () => {
  const { setAuth } = useAuthListener();
  const navigate = useNavigate();

  const inputs = [
    {
      name: "email",
      title: "Correo",
      type: "email",
      placeholder: "Escribir correo",
    },
    {
      name: "password",
      title: "Contraseña",
      type: "password",
      placeholder: "Escribir contraseña",
    },
  ];

  const onSuccess = async (body: any) => {
    setAuth(body);
    navigate("/");
  };

  return (
    <ScaffoldAuth
      inputs={inputs}
      service={services.login}
      title="Iniciar sesión"
      buttonText="Iniciar"
      navigateSuccess={onSuccess}
      footer={
        <>
          <Link to="/auth/recover">
            No recuerdas tu contraseña. Recupérala aquí.
          </Link>
        </>
      }
    />
  );
};
