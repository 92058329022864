/** @jsxImportSource @emotion/react */
import * as styles from "./styles";

export interface MessageProps {
  children: string;
  className?: string;
}

export const Message = ({ children, className, ...props }: MessageProps) => {
  return (
    <article className={className} css={styles.article}>
      <p css={styles.p}>{children}</p>
    </article>
  );
};
