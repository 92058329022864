import { useNavigate, useSearchParams } from "react-router-dom";
import { services } from "../../../services";
import { ScaffoldAuth } from "../../../ui";

export const AuthConfirmAccount = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const inputs = [
    {
      name: "password",
      title: "Contraseña",
      type: "password",
      placeholder: "Escribir contraseña",
    },
    {
      name: "passwordConfirm",
      title: "Repetir Contraseña",
      type: "password",
      placeholder: "Escribir contraseña nuevamente",
    },
  ];

  const onSuccess = async () => {
    navigate("/login");
  };

  return (
    <ScaffoldAuth
      position="right"
      inputs={inputs}
      service={(payload: any) =>
        services.confirmAccount(searchParams.get("token") || "", payload)
      }
      title="Confirmar Cuenta"
      buttonText="Confirmar"
      navigateSuccess={onSuccess}
    />
  );
};
