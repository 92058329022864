export const utilsRoutes = {
  addParams: (params: any) => {
    if (!params) return "";
    if (Object.keys(params).length === 0) return "";

    let resp = "";
    Object.entries(params).forEach((entry, index) => {
      const key = entry[0];
      const value = entry[1];

      if (value) {
        if (index === 0) {
          resp += `?${key}=${value}`;
        } else {
          resp += `&${key}=${value}`;
        }
      }
    });
    return resp;
  },
};
