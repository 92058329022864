import { v4 as uuidv4 } from "uuid";
import { createContext, useContext, useState } from "react";

import { Alert, AlertProps } from "./component";

interface AlertContextProps {
  addAlert: (alertProps: AlertProps) => void;
}

const AlertContext = createContext<AlertContextProps>({
  addAlert: () => {},
});

interface AlertProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<AlertProps[]>([]);

  const addAlert = (alertProps: AlertProps) => {
    const id = uuidv4();

    setAlerts((state) => {
      return [
        ...state,
        { ...alertProps, className: "animate__fadeInDown", id },
      ];
    });

    setTimeout(() => {
      _endAlert(id);
    }, 3000);
  };

  const onClose = (id?: string) => {
    if (id) {
      _endAlert(id);
    }
  };

  const _endAlert = (id: string) => {
    setAlerts((state) => {
      return state.map((state) => {
        if (state.id === id) {
          return (state = {
            ...state,
            className: "animate__fadeOutUp",
          });
        } else {
          return state;
        }
      });
    });

    setTimeout(() => {
      setAlerts((state) => {
        return state.filter((state) => state.id !== id);
      });
    }, 800);
  };

  return (
    <AlertContext.Provider
      value={{
        addAlert,
      }}
    >
      <>
        {children}
        {alerts.map((alert, index) => {
          return <Alert key={index} onClose={onClose} {...alert} />;
        })}
      </>
    </AlertContext.Provider>
  );
};

export const useAlertListener = () => {
  return useContext(AlertContext);
};
