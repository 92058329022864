import * as authServices from "./auth";
import * as usersServices from "./users";
import * as blogServices from "./blogs";
import * as newsServices from "./news";
import * as prospectsServices from "./prospects";
import * as dashboardServices from "./dashboard";

export const services = {
  ...authServices,
  ...usersServices,
  ...blogServices,
  ...newsServices,
  ...prospectsServices,
  ...dashboardServices,
};
