import { css } from "@emotion/react";

import * as ui from "../../../../";

export const inputContainer = css`
  ${ui.column("xs")};
`;

export const loadingContainer = css`
  ${ui.column("sm")};
`;

export const inputLabel = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(14)};
  color: #000000;
`;

export const inputInputFile = css`
  display: none;
`;

export const inputFileImageContainer = css`
  width: ${ui.rem(200)};
  height: ${ui.rem(150)};
  border: 1px solid black;
  position: relative;
`;

export const inputFileImage = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const trashContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${ui.flexCenter()};
  transition: background-color 300ms;
  cursor: pointer;
  user-select: none;

  :hover {
    background-color: #00000030;

    svg {
      opacity: 1;
    }
  }

  :active {
    background-color: #00000044;
  }
`;

export const trash = css`
  color: #ffffff;
  font-size: ${ui.rem(30)};
  opacity: 0;
  transition: opacity 600ms;
`;

export const inputFileAdd = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(16)};
  line-height: ${ui.rem(22)};
  text-align: center;
  margin-right: auto;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};

  background-color: #ec6036;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 300ms;

  :hover {
    background-color: #d24f27;
  }
`;
