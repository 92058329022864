/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import * as styles from "./styles";

export interface InputTextAreaAdvanceProps {
  onChange: (event: any) => void;
  form: any;
  label: string;
  name: string;
  placeholder: string;
}

export const InputTextAreaAdvance = ({
  label,
  name,
  placeholder,
  form,
  onChange,
}: InputTextAreaAdvanceProps) => {
  const theme = "snow";
  const modules = {
    toolbar: [["bold", "italic", "underline", "strike"]],
  };

  const { quill, quillRef } = useQuill({
    theme,
    modules,
    placeholder,
  });
  const value = form[name];

  useEffect(() => {
    if (quill) {
      if (value) {
        quill.clipboard.dangerouslyPasteHTML(value);
      }
      quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
        const value = quill.root.innerHTML;
        if (onChange) {
          onChange({
            target: {
              name,
              value,
            },
          } as any);
        }
      });
    }
  }, [quill]);

  return (
    <div css={styles.inputContainer}>
      <label css={styles.inputLabel}>{label}</label>
      <div css={styles.quillContainer}>
        <div ref={quillRef} />
      </div>
    </div>
  );
};
