import { Interpolation, Theme } from "@emotion/react";

// THEME
export type ThemeSizesTypes =
  | "xxxl"
  | "xxl"
  | "xl"
  | "lg"
  | "md"
  | "sm"
  | "xs"
  | "xxs";

export const isSizeType = (value: any): boolean => {
  if (
    value === "xxs" ||
    value === "xs" ||
    value === "sm" ||
    value === "md" ||
    value === "lg" ||
    value === "xl" ||
    value === "xxl" ||
    value === "xxxl"
  ) {
    return true;
  } else {
    return false;
  }
};

export const getSizeTypeBefore = (value: ThemeSizesTypes): ThemeSizesTypes => {
  if (value === "xxxl") {
    return "xxl";
  }

  if (value === "xxl") {
    return "xl";
  }

  if (value === "xl") {
    return "lg";
  }

  if (value === "lg") {
    return "md";
  }

  if (value === "md") {
    return "sm";
  }

  if (value === "sm") {
    return "xs";
  }

  if (value === "xs") {
    return "xxs";
  }

  return "xxs";
};

export interface ThemeSizes {
  xxs: any;
  xs: any;
  sm: any;
  md: any;
  lg: any;
  xl: any;
  xxl: any;
  xxxl: any;
}

// COMPONENTS
export interface ComponentsProps {
  inverted?: boolean;
  _ref?: any;
}
