/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./styles";

export interface CardProps {
  title: string;
  checked?: boolean;
  description?: string;
  src?: string;
  onClick?: () => void;
}

export const Card = ({
  title,
  checked,
  description,
  src,
  onClick,
  ...props
}: CardProps) => {
  return (
    <article onClick={onClick} css={styles.article(!!onClick)}>
      {src && (
        <div css={styles.imageContainer}>
          <img css={styles.image} src={src} alt={title} />
        </div>
      )}
      <div css={styles.texts}>
        {title && <h3 css={styles.title}>{title}</h3>}
        {description && <p css={styles.description}>{description}</p>}
        {onClick && (
          <button css={styles.button} onClick={onClick}>
            Ver más <FontAwesomeIcon icon={faArrowRight} />
          </button>
        )}
      </div>
      {checked && (
        <FontAwesomeIcon css={styles.iconChecked} icon={faCheckCircle} />
      )}
    </article>
  );
};
