/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Container } from "../container";
import { Message } from "../message";
import { LoadingBarComponent } from "../loading";
import { useAlertListener } from "../alert";

import * as styles from "./styles";

export interface ListComponentProps {
  child: (doc: any) => JSX.Element | JSX.Element[];
  root: string;
  messageEmpty: string;
  extraFilters?: any[];
  service: (payload: any) => any;
}

export const ListComponent = ({
  root,
  child,
  messageEmpty,
  extraFilters = [],
  service,
  ...props
}: ListComponentProps) => {
  const { addAlert } = useAlertListener();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState(root);
  const [resp, setResp] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const query = searchParams.get("query") || "";
  const date = searchParams.get("date") || "";
  const contacted = searchParams.get("contacted") || "";

  const onSearch = useCallback((event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    let url = root;
    let validation = false;
    [
      "query",
      "date",
      "page",
      "limit",
      ...extraFilters.map((value) => value.name),
    ].forEach((query) => {
      const _value = name === query ? value : searchParams.get(query);

      if (
        name === query
          ? value !== undefined && value !== null
          : searchParams.get(query)
      ) {
        if (!validation) {
          url += `?${query}=${_value}`;
        } else {
          url += `&${query}=${_value}`;
        }
        validation = true;
      }
    });

    setUrl(url);
    navigate(url);
  }, []);

  useEffect(() => {
    getValues(searchParams);
  }, [url]);

  const getValues = useCallback(
    _.debounce(async (searchParams) => {
      try {
        document.body.style.cursor = "wait";
        const query = searchParams.get("query") || "";
        const date = searchParams.get("date") || "";
        const page = searchParams.get("page") || "1";
        const limit = searchParams.get("limit") || "30";

        const extraQuery: any = {};
        extraFilters
          .map((value) => value.name)
          .forEach((extraFilter: any) => {
            extraQuery[extraFilter] = searchParams.get(extraFilter) || "";
          });

        setLoading(true);
        const resp = await service({
          limit: parseInt(limit),
          page: parseInt(page),
          query,
          date,
          ...extraQuery,
        });

        setLoading(false);
        setResp(resp.data);
        document.body.style.cursor = "default";
      } catch (error) {
        document.body.style.cursor = "default";
        setLoading(false);
        addAlert({
          title: "Hubo un problema, intentarlo más tarde.",
          type: "alert",
        });
      }
    }, 300),
    []
  );

  const pagination = resp?.body;
  const page = pagination?.page;
  const nextPage = pagination?.nextPage;
  const prevPage = pagination?.prevPage;

  const filters = [
    {
      label: "Buscar",
      name: "query",
      value: query,
      type: "text",
      placeholder: "Buscador",
    },
    ...extraFilters,
    {
      label: "Desde",
      name: "date",
      value: date,
      type: "date",
      placeholder: "Escribir fecha",
    },
  ];

  const empty = !pagination || pagination.docs.length === 0;

  return (
    <section id="list" css={styles.section}>
      <header css={styles.header}>
        <Container css={styles.filterContainer}>
          <>
            <p css={styles.filterTitle}>Buscar</p>
            {filters.map((filter: any, index) => {
              const isLastOne = index === 1;

              if (filter.type === "options-buttons") {
                const getNextOption = (index: number) => {
                  if (index === filter.options.length - 1) {
                    return filter.options[0].value;
                  } else {
                    return filter.options[index + 1].value;
                  }
                };

                return (
                  <div key={index} css={styles.filterInputContainer(isLastOne)}>
                    {filter.options.map((option: any, index: any) => {
                      if (
                        (contacted === "false"
                          ? false
                          : !contacted
                          ? null
                          : true) === option.value
                      ) {
                        return (
                          <button
                            css={styles.optionsButtons}
                            key={index}
                            name={option.name}
                            onClick={() =>
                              onSearch({
                                target: {
                                  name: filter.name,
                                  type: filter.type,
                                  value: getNextOption(index),
                                },
                              })
                            }
                          >
                            {option.label}
                          </button>
                        );
                      }
                    })}
                  </div>
                );
              } else {
                return (
                  <div key={index} css={styles.filterInputContainer(isLastOne)}>
                    <input
                      css={styles.filterInput}
                      name={filter.name}
                      onChange={onSearch}
                      value={filter.value}
                      placeholder={filter.placeholder}
                      type={filter.type}
                    />
                  </div>
                );
              }
            })}
          </>
        </Container>
      </header>

      <Container css={styles.listContainer}>
        {loading ? (
          <div
            className="animate__animated animate__fadeIn"
            css={styles.loadingContainer}
          >
            <LoadingBarComponent />
            <LoadingBarComponent />
            <LoadingBarComponent />
            <LoadingBarComponent />
          </div>
        ) : empty ? (
          <Message className="animate__animated animate__fadeIn">
            {messageEmpty}
          </Message>
        ) : (
          <ul className="animate__animated animate__fadeIn" css={styles.list}>
            {(pagination?.docs as any[]).map((doc) => {
              return (
                <li key={doc._id} css={styles.element}>
                  {child(doc)}
                </li>
              );
            })}
          </ul>
        )}
      </Container>

      <footer css={styles.footer}>
        <Container css={styles.footerContainer}>
          <button
            css={styles.footerButton(prevPage)}
            onClick={() =>
              onSearch({
                target: {
                  name: "page",
                  value: prevPage,
                },
              })
            }
          >
            {prevPage || "-"}
          </button>
          <p css={styles.footerPage}>{page}</p>
          <button
            css={styles.footerButton(nextPage)}
            onClick={() =>
              onSearch({
                target: {
                  name: "page",
                  value: nextPage,
                },
              })
            }
          >
            {nextPage || "-"}
          </button>
        </Container>
      </footer>
    </section>
  );
};
