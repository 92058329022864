import _ from "lodash";
import axios from "axios";
import { environments } from "../enviroments";
import { utilsRoutes } from "./_utils";

// NEWS LIST
export interface NewsListServiceProps {
  page: number;
  limit: number;
}

export const newsList = async (payload: NewsListServiceProps) => {
  const params = utilsRoutes.addParams(payload);
  return axios.get(`${environments.API}/news${params}`, {
    withCredentials: true,
  });
};

// NEWS CREATE
export interface NewsCreateServiceProps {
  title: string;
  url: string;
  image: File;
}

export const newsCreate = async (payload: NewsCreateServiceProps) => {
  const formData = new FormData();

  Object.entries(payload).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];

    if (value?.size) {
      formData.append("file", value);
    } else {
      formData.append(key, value);
    }
  });

  return axios.post(`${environments.API}/news`, formData, {
    withCredentials: true,
  });
};

// NEWS SHOW
export const newsShow = async (id: string) => {
  return axios.get(`${environments.API}/news/${id}`, { withCredentials: true });
};

// NEWS UPDATE
export interface NewsUpdateServiceProps {
  title: string;
  url: string;
  image: string;
}

export const newsUpdate = async (
  id: string,
  payload: NewsUpdateServiceProps
) => {
  const formData = new FormData();

  Object.entries(payload).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];

    if (value?.size) {
      formData.append("file", value);
    } else {
      formData.append(key, value);
    }
  });

  return axios.put(`${environments.API}/news/${id}`, formData, {
    withCredentials: true,
  });
};

// NEWS DELETE
export const newsDestroy = async (id: string) => {
  return axios.delete(`${environments.API}/news/${id}`, {
    withCredentials: true,
  });
};

// NEWS-IMAGE DOWNLOAD
export const newsImageDownload = (id: string) => {
  if (!id) return undefined;
  return `${environments.API}/news-image/${id}`;
};
