import { Route } from "react-router-dom";

import { BlogCreate, BlogEdit, BlogList } from "../views";

export const RouterBlog = () => {
  return (
    <Route path="blog">
      <Route index element={<BlogList />} />
      <Route path="create" element={<BlogCreate />} />
      <Route path=":id" element={<BlogEdit />} />
    </Route>
  );
};
