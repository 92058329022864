import { Route } from "react-router-dom";
import { NewsCreate, NewsEdit, NewsList } from "../views";

export const RouterNews = () => {
  return (
    <Route path="news">
      <Route index element={<NewsList />} />
      <Route path="create" element={<NewsCreate />} />
      <Route path=":id" element={<NewsEdit />} />
    </Route>
  );
};
