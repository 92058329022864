import { css } from "@emotion/react";

import * as ui from "../../../ui";

export const appbar = css`
  ${ui.row("md")}
  background: #EC6036;
  height: ${ui.rem(100)};
  width: 100%;
  color: #ffffff;
  align-items: center;
  position: fixed;
  justify-content: center;
  z-index: 1;

  @media (min-width: ${ui.breakpoints.lg}px) {
    width: calc(100% - ${ui.rem(250)});
  }
`;

export const container = css`
  margin: 0px;
  ${ui.row("md")};
  align-items: center;

  svg {
    font-size: ${ui.rem(30)};
  }
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(30)};
  line-height: ${ui.rem(32)};
  color: #ffffff;
  margin-right: auto;

  @media (min-width: ${ui.breakpoints.lg}px) {
    font-size: ${ui.rem(32)};
    line-height: ${ui.rem(40)};
  }
`;

export const actionsContainer = css`
  ${ui.row("lg")};
`;

export const buttonIcon = css`
  background-color: transparent;
  cursor: pointer;

  svg {
    color: #ffffff;
    transition: font-size 300ms;
    font-size: ${ui.rem(30)};
  }

  svg.fa-trash {
    font-size: ${ui.rem(25)};
  }

  :hover {
    svg {
      // font-size: ${ui.rem(32)};
    }
  }
`;

export const menu = css`
  ${buttonIcon}

  @media (min-width: ${ui.breakpoints.lg}px) {
    display: none;
  }
`;
