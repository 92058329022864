import axios from "axios";
import { environments } from "../enviroments";
import { utilsRoutes } from "./_utils";

// USERS LIST
export interface UserListServiceProps {
  page: number;
  limit: number;
  query: string;
  date: string;
}

export const usersList = async (payload: UserListServiceProps) => {
  const params = utilsRoutes.addParams(payload);
  return axios.get(`${environments.API}/users${params}`, {
    withCredentials: true,
  });
};

// USERS CREATE
export interface UserCreateServiceProps {
  name: string;
  email: string;
  permissions: {
    admin: boolean;
    users: boolean;
    news: boolean;
    blogs: boolean;
    prospects: boolean;
  };
}

export const usersCreate = async (payload: UserCreateServiceProps) => {
  return axios.post(`${environments.API}/users`, payload, {
    withCredentials: true,
  });
};

// USERS SHOW
export const usersShow = async (id: string) => {
  return axios.get(`${environments.API}/users/${id}`, {
    withCredentials: true,
  });
};

// USERS UPDATE
export interface UserUpdateServiceProps {
  name: string;
  email: string;
  permissions: {
    admin: boolean;
    users: boolean;
    news: boolean;
    blogs: boolean;
    prospects: boolean;
  };
}

export const usersUpdate = async (
  id: string,
  payload: UserUpdateServiceProps
) => {
  return axios.put(`${environments.API}/users/${id}`, payload, {
    withCredentials: true,
  });
};

// USERS DELETE
export const usersDestroy = async (id: string) => {
  return axios.delete(`${environments.API}/users/${id}`, {
    withCredentials: true,
  });
};
