/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { services } from "../../../services";
import { EditComponent, ScaffoldMain, useAlertListener } from "../../../ui";

import * as styles from "./styles";

export const NewsEdit = () => {
  const params = useParams();
  const { addAlert } = useAlertListener();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [initForm, setInitForm] = useState({
    "title-spanish": "",
    "category-spanish": "",
    "title-english": "",
    "category-english": "",
    url: "",
    image: null as any,
  });

  const inputs = [
    {
      type: "subtitle",
      label: "Español",
    },
    {
      type: "text",
      placeholder: "Escribir título",
      label: "Título",
      name: "title-spanish",
    },
    {
      type: "text",
      placeholder: "Escribir categoría",
      label: "Categoría",
      name: "category-spanish",
    },
    {
      type: "space",
      value: "sm",
    },
    {
      type: "subtitle",
      label: "Ingles",
    },
    {
      type: "text",
      placeholder: "Escribir título",
      label: "Título",
      name: "title-english",
    },
    {
      type: "text",
      placeholder: "Escribir categoría",
      label: "Categoría",
      name: "category-english",
    },
    {
      type: "space",
      value: "sm",
    },
    {
      type: "subtitle",
      label: "Más información",
    },
    {
      type: "text",
      placeholder: "Escribir enlance",
      label: "Enlace",
      name: "url",
    },
    {
      type: "file",
      placeholder: "Seleccionar imagen",
      label: "Imagen",
      name: "image",
    },
  ];
  useEffect(() => {
    getNew();
  }, []);

  const getNew = async () => {
    try {
      setLoading(true);
      const resp = await services.newsShow(params.id || "");

      if (resp?.data?.body) {
        setInitForm({
          ..._.pick(resp.data.body, [
            "title-spanish",
            "category-spanish",
            "title-english",
            "category-english",
            "url",
          ]),
          image: services.newsImageDownload(resp.data.body["image"]),
        });
      } else {
        navigate(-1);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      addAlert({
        title: "Hubo un problema, intentarlo más tarde.",
        type: "alert",
      });
    }
  };

  const onReturn = async () => {
    navigate(-1);
  };

  const navigateSuccess = async () => {
    navigate("/news", { replace: true });
  };

  const navigateDestroy = async () => {
    navigate("/news", { replace: true });
  };

  return (
    <ScaffoldMain
      root="news"
      title="Editar noticia"
      onReturn={onReturn}
      destroy={{
        id: params.id || "",
        serviceDestroy: services.newsDestroy,
        navigateDestroy,
      }}
    >
      <EditComponent
        initLoading={loading}
        initForm={initForm}
        inputs={inputs}
        service={(payload: any) =>
          services.newsUpdate(params.id || "", {
            ...payload,
            image:
              typeof payload.image === "string"
                ? params.id
                : typeof payload.image === "object"
                ? payload.image
                : null,
          })
        }
        navigateSuccess={navigateSuccess}
        isEdit
      />
    </ScaffoldMain>
  );
};
