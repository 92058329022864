/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import { services } from "../../../services";
import { ScaffoldAuth } from "../../../ui";

export const AuthRecover = () => {
  const navigate = useNavigate();

  const inputs = [
    {
      name: "email",
      title: "Email",
      type: "email",
      placeholder: "Escribir email",
    },
  ];

  const onSuccess = async () => {
    navigate("/login");
  };

  return (
    <ScaffoldAuth
      position="right"
      inputs={inputs}
      service={services.recover}
      title="Recuperar cuenta"
      buttonText="Recuperar"
      navigateSuccess={onSuccess}
    />
  );
};
