import axios from "axios";
import { environments } from "../enviroments";
import { utilsRoutes } from "./_utils";

// BLOGS LIST
export interface BlogsListServiceProps {
  page: number;
  limit: number;
}

export const blogList = async (payload: BlogsListServiceProps) => {
  const params = utilsRoutes.addParams(payload);
  return axios.get(`${environments.API}/blog${params}`, {
    withCredentials: true,
  });
};

// BLOGS CREATE
export interface BlogsCreateServiceProps {
  title: string;
  content: string;
  createdBy: string;
}

export const blogCreate = async (payload: BlogsCreateServiceProps) => {
  const formData = new FormData();
  Object.entries(payload).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];

    if (value?.size) {
      formData.append("file", value);
    } else {
      formData.append(key, value);
    }
  });

  return axios.post(`${environments.API}/blog`, formData, {
    withCredentials: true,
  });
};

// BLOGS SHOW
export const blogShow = async (id: string) => {
  return axios.get(`${environments.API}/blog/${id}`, { withCredentials: true });
};

// BLOGS UPDATE
export interface BlogsUpdateServiceProps {
  title: string;
  content: string;
  createdBy: string;
  image: string;
}

export const blogUpdate = async (
  id: string,
  payload: BlogsUpdateServiceProps
) => {
  const formData = new FormData();

  Object.entries(payload).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];

    if (value?.size) {
      formData.append("file", value);
    } else {
      formData.append(key, value);
    }
  });

  return axios.put(`${environments.API}/blog/${id}`, formData, {
    withCredentials: true,
  });
};

// BLOGS DELETE
export const blogDestroy = async (id: string) => {
  return axios.delete(`${environments.API}/blog/${id}`, {
    withCredentials: true,
  });
};

// BLOGS-IMAGE DELETE
export const blogImageDownload = (id: string) => {
  if (!id) return undefined;
  return `${environments.API}/blog-image/${id}`;
};
