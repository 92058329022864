/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Auth, useAuthListener } from "../../../auth";
import { services } from "../../../services";
import { EditComponent, ScaffoldMain, useAlertListener } from "../../../ui";

import * as styles from "./styles";

export const UsersEdit = () => {
  const params = useParams();
  const { auth, setAuth } = useAuthListener();
  const { addAlert } = useAlertListener();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initForm, setInitForm] = useState({
    name: "",
    email: "",
    "permissions.news": false,
    "permissions.blogs": false,
    "permissions.prospects": false,
    "permissions.users": false,
  });

  const inputs = [
    {
      type: "text",
      placeholder: "Escribir nombre",
      label: "Nombre",
      name: "name",
    },
    {
      type: "email",
      placeholder: "Escribir email",
      label: "Email",
      name: "email",
    },
    {
      type: "checkbox",
      label: "Permisos",
      elements: [
        {
          name: "permissions.users",
          label: "Usuarios",
        },
        {
          name: "permissions.news",
          label: "Noticias",
        },
        {
          name: "permissions.blogs",
          label: "Blog",
        },
        {
          name: "permissions.prospects",
          label: "Prospectos",
        },
      ],
    },
  ];

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setLoading(true);
      const resp = await services.usersShow(params.id || "");

      if (resp?.data?.body) {
        setInitForm({
          ..._.pick(resp.data.body, ["email", "name"]),
          "permissions.news": resp.data.body.permissions.news,
          "permissions.blogs": resp.data.body.permissions.blogs,
          "permissions.prospects": resp.data.body.permissions.prospects,
          "permissions.users": resp.data.body.permissions.users,
        });
      } else {
        navigate(-1);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      addAlert({
        title: "Hubo un problema, intentarlo más tarde.",
        type: "alert",
      });
    }
  };

  const onReturn = async () => {
    navigate(-1);
  };

  const navigateSuccess = async (body: any) => {
    if (body._id === (auth as Auth)._id) {
      setAuth(body);
    }
    navigate("/users", { replace: true });
  };

  const navigateDestroy = async () => {
    navigate("/users", { replace: true });
  };

  return (
    <ScaffoldMain
      root="users"
      title="Editar usuario"
      onReturn={onReturn}
      destroy={{
        id: params.id || "",
        serviceDestroy: services.usersDestroy,
        navigateDestroy,
      }}
    >
      <EditComponent
        initLoading={loading}
        initForm={initForm}
        inputs={inputs}
        service={(payload: any) =>
          services.usersUpdate(params.id || "", payload)
        }
        navigateSuccess={navigateSuccess}
        isEdit
      />
    </ScaffoldMain>
  );
};
