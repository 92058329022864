import axios from "axios";
import { environments } from "../enviroments";
import { utilsRoutes } from "./_utils";

// PROSPECTS LIST
export interface DashboardServiceProps {
  page: number;
  limit: number;
}

export const dashboardMain = async () => {
  return axios.get(`${environments.API}/dashboard`, {
    withCredentials: true,
  });
};
