/** @jsxImportSource @emotion/react */
import React from "react";

import * as styles from "./styles";

/*
========
Other loading elements components
========
*/

interface LoadingElementProps extends React.ComponentProps<"div"> {
  nada?: string;
}

export const LoadingBarComponent = ({ ...props }: LoadingElementProps) => {
  return (
    <div
      className="loading_element loading_bar"
      css={styles.styleBar}
      {...props}
    ></div>
  );
};

export const LoadingCircleComponent = ({ ...props }: LoadingElementProps) => {
  return (
    <div
      className="loading_element loading_cirlce"
      css={styles.styleCircle}
      {...props}
    ></div>
  );
};
