import { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { useAuthListener } from "../auth";
import { Dashboard } from "../views";

export const RouterDashboard = () => {
  const navigate = useNavigate();
  const { auth } = useAuthListener();

  useEffect(() => {
    if (!auth) {
      navigate("/login");
    }
  }, [auth]);

  return (
    <>
      <Route path="/" element={<Dashboard />} />
    </>
  );
};
