import { Route } from "react-router-dom";
import {
  AuthChangePassword,
  AuthConfirmAccount,
  AuthLogin,
  AuthRecover,
} from "../views";

export const RouterAuth = () => {
  return (
    <>
      <Route path="/login" element={<AuthLogin />} />
      <Route path="/auth/recover" element={<AuthRecover />} />
      <Route path="/auth/confirm-account" element={<AuthConfirmAccount />} />
      <Route path="/auth/change-password" element={<AuthChangePassword />} />
    </>
  );
};
