export type ColorLuminanceTypes =
  | "darker"
  | "dark"
  | "normal"
  | "light"
  | "lighter";
export type ColorOpacityTypes = "xs" | "sm" | "md" | "lg" | "xl";

class UtilsColor {
  luminance(hex: string, lum: number | ColorLuminanceTypes) {
    let _lum: number = 0;

    if (lum === "darker") {
      _lum = -20;
    } else if (lum === "dark") {
      _lum = -10;
    } else if (lum === "normal") {
      _lum = 0;
    } else if (lum === "light") {
      _lum = 10;
    } else if (lum === "lighter") {
      _lum = 20;
    } else if (typeof lum === "number") {
      _lum = lum;
    }

    let R = parseInt(hex.substring(1, 3), 16);
    let G = parseInt(hex.substring(3, 5), 16);
    let B = parseInt(hex.substring(5, 7), 16);

    R = parseInt(((R * (100 + _lum)) / 100).toString());
    G = parseInt(((G * (100 + _lum)) / 100).toString());
    B = parseInt(((B * (100 + _lum)) / 100).toString());

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    let RR =
      R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    let GG =
      G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    let BB =
      B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  }

  text(
    color: string,
    { light = "#ffffff", dark = "#000000" }: { light?: string; dark?: string }
  ) {
    var nThreshold = 105;
    var components = UtilsColor.getRGBComponents(color);
    var bgDelta =
      components.R * 0.299 + components.G * 0.587 + components.B * 0.114;

    return 255 - bgDelta < nThreshold ? dark : light;
  }

  opacity(color: string, opacity: number | ColorOpacityTypes) {
    let _opacity: number = 0;

    if (opacity === "xs") {
      _opacity = 0.9;
    } else if (opacity === "sm") {
      _opacity = 0.85;
    } else if (opacity === "md") {
      _opacity = 0.5;
    } else if (opacity === "lg") {
      _opacity = 0.3;
    } else if (opacity === "xl") {
      _opacity = 0.2;
    } else if (typeof opacity === "number") {
      _opacity = opacity;
    }

    // coerce values so ti is between 0 and 1.
    const opacityUpdate = Math.round(
      Math.min(Math.max(_opacity || 1, 0), 1) * 255
    );
    return color + opacityUpdate.toString(16).toUpperCase();
  }

  static getRGBComponents(color: string) {
    var r = color.substring(1, 3);
    var g = color.substring(3, 5);
    var b = color.substring(5, 7);

    return {
      R: parseInt(r, 16),
      G: parseInt(g, 16),
      B: parseInt(b, 16),
    };
  }
}

export const colors = new UtilsColor();
