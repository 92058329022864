import { css } from "@emotion/react";

import * as ui from "../..";

export const section = css`
  width: 100%;
  ${ui.column("md")};
  padding-bottom: ${ui.rem("lg")};
  min-height: calc(100vh - ${ui.rem(100)});
`;

export const header = css`
  ${ui.row("md")}
  background-color: #EDEDED;
  width: 100%;
  height: ${ui.rem(62)};
`;

export const form = css`
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  ${ui.column("md")};
`;

export const inputContainer = css`
  ${ui.column("xs")};
`;

export const loadingContainer = css`
  ${ui.column("sm")};
`;

export const inputSubtitle = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(18)};
  line-height: ${ui.rem(18)};
  color: #000000;
`;

export const inputSpace = (value: string) => css`
  height: ${ui.rem(value as any)};
`;

export const inputLabel = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(14)};
  color: #000000;
`;

export const inputFileAdded = css`
  ${ui.column("xs")};
`;

export const inputInputFile = css`
  display: none;
`;

export const inputFileImageContainer = css`
  width: ${ui.rem(200)};
  height: ${ui.rem(150)};
  border: 1px solid black;
`;

export const inputFileImage = css`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const inputFileDelete = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(14)};
  margin-right: auto;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  background-color: #ec6036;
  color: #ffffff;
  cursor: pointer;

  :hover {
    background-color: #d24f27;
  }
`;

export const inputFileAdd = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(14)};
  margin-right: auto;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  background-color: #ec6036;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 300ms;

  :hover {
    background-color: #d24f27;
  }
`;

export const inputBoolean = (active: boolean) => css`
  height: ${ui.rem(30)};
  width: ${ui.rem(50)};
  background-color: #ededed;
  border-radius: ${ui.rem("xs")};
  position: relative;
  cursor: pointer;

  :before {
    content: "";
    display: block;
    height: ${ui.rem(20)};
    width: ${ui.rem(20)};
    top: 0px;
    position: absolute;
    margin: ${ui.rem("xs")};
    border-radius: ${ui.rem("xs")};
    transition: left 300ms, background-color 300ms;

    ${active
      ? `
        background-color: #ec6036;
        left: ${ui.rem(20)};
    `
      : `
        background-color: #C4C4C4;
        left: ${ui.rem(0)};
      `};
  }
`;

export const inputInput = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(30)};
  color: #000000;
  padding: 0px;
  border-bottom: 1px solid #000000;

  :focus,
  :focus-within,
  :focus-visible,
  :visited {
    outline: none;
  }
`;

export const checkboxLists = css`
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(100%, ${100 / 16}rem), 1fr)
  );
  ${ui.gridRepeat("xs")};
`;

export const checkboxContainer = css`
  ${ui.row("xs")};
  align-items: center;
`;

export const checkboxLabel = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(14)};
  color: #000000;
`;

export const footer = css`
  align-self: flex-end;
  margin-top: auto;
`;

export const footerContainer = css`
  ${ui.row("xs")};
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const footerButton = (active: boolean) => css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: ${ui.rem(16)};
  line-height: ${ui.rem(22)};
  text-align: center;
  color: #ffffff;
  background-color: #545454;
  transition: box-shadow 300ms;
  padding: ${ui.rem("xs")} ${ui.rem("sm")};
  border: 1px solid #000000;

  ${active
    ? `
  cursor:pointer;
  :hover {
      box-shadow: 1px ${ui.rem(2)} ${ui.rem(2)} #a1a1a1;
  }

  `
    : "opacity: 0.2;"}
`;
