import { Route } from "react-router-dom";
import { ProspectsEdit, ProspectsList } from "../views";

export const RouterProspects = () => {
  return (
    <Route path="prospects">
      <Route index element={<ProspectsList />} />
      <Route path=":id" element={<ProspectsEdit />} />
    </Route>
  );
};
