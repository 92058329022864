/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useAlertListener } from "../alert";

import { Container } from "../container";
import { LoadingBarComponent } from "../loading";
import { InputFile, InputTextArea, InputTextAreaAdvance } from "./_utils";

import * as styles from "./styles";

export interface EditComponentProps {
  initForm: any;
  inputs: any[];
  service: (payload: any, file?: File) => any;
  navigateSuccess: (body: any) => Promise<any>;
  initLoading?: boolean;
  isEdit?: boolean;
}

export const EditComponent = ({
  initForm = {},
  isEdit = false,
  inputs,
  service,
  navigateSuccess,
  initLoading,
  ...props
}: EditComponentProps) => {
  const [form, setForm] = useState<any>(initForm);
  const { addAlert } = useAlertListener();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setForm(initForm);
    }
  }, [initForm]);

  const onSubmit = async () => {
    setLoading(true);
    document.body.style.cursor = "wait";
    try {
      const resp = await service({
        ...form,
      });
      const data = resp.data;

      if (data.status) {
        addAlert({ title: data.message, type: "success" });
        navigateSuccess(data.body);
      } else {
        addAlert({ title: data.message, type: "alert" });
      }
      setLoading(false);
      document.body.style.cursor = "default";
    } catch (error) {
      document.body.style.cursor = "default";
      setLoading(false);
      addAlert({
        title: "Hubo un problema, intentarlo más tarde.",
        type: "alert",
      });
    }
  };

  const onChange = (event: any) => {
    const target = event?.target;
    const name = target?.name;
    const type = target?.type;
    const value = target?.value;
    const checked = target?.checked;
    const file = target?.files ? target?.files[0] : null;

    setForm((state: any) => {
      return {
        ...state,
        [name]: type === "checkbox" ? checked : type === "file" ? file : value,
      };
    });
  };

  return (
    <section
      id="create"
      className="animate__animated animate__fadeIn"
      css={styles.section}
    >
      <header css={styles.header}>
        <Container css={styles.inputContainer}>
          <></>
        </Container>
      </header>
      {initLoading ? (
        <Container
          className="animate__animated animate__fadeIn"
          css={styles.loadingContainer}
        >
          <LoadingBarComponent />
          <LoadingBarComponent />
          <LoadingBarComponent />
          <LoadingBarComponent />
        </Container>
      ) : (
        <>
          <Container
            className="animate__animated animate__fadeIn"
            css={styles.form}
          >
            <>
              {inputs.map((input, index) => {
                if (input.type === "checkbox") {
                  return (
                    <div key={index} css={styles.inputContainer}>
                      <label css={styles.inputLabel}>{input.label}</label>
                      <div css={styles.checkboxLists}>
                        {input.elements.map((element: any) => {
                          return (
                            <div
                              key={element.name}
                              css={styles.checkboxContainer}
                            >
                              <input
                                css={styles.inputInput}
                                id={element.name}
                                name={element.name}
                                checked={form[element.name] || false}
                                type="checkbox"
                                onChange={onChange}
                              />
                              <label
                                css={styles.checkboxLabel}
                                htmlFor={element.name}
                              >
                                {element.label}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else if (input.type === "subtitle") {
                  return (
                    <h2 key={index} css={styles.inputSubtitle}>
                      {input.label}
                    </h2>
                  );
                } else if (input.type === "space") {
                  return (
                    <div key={index} css={styles.inputSpace(input.value)} />
                  );
                } else if (input.type === "textarea") {
                  return (
                    <InputTextArea
                      key={index}
                      form={form}
                      onChange={onChange}
                      {...input}
                    />
                  );
                } else if (input.type === "textarea-advance") {
                  return (
                    <InputTextAreaAdvance
                      key={index}
                      form={form}
                      onChange={onChange}
                      {...input}
                    />
                  );
                } else if (input.type === "boolean") {
                  return (
                    <div key={index} css={styles.inputContainer}>
                      <label css={styles.inputLabel}>
                        {form[input.name] ? input.label : input.labelNot}
                      </label>
                      <div
                        onClick={() =>
                          onChange({
                            target: {
                              type: "checkbox",
                              name: input.name,
                              checked: !form[input.name],
                            },
                          })
                        }
                        css={styles.inputBoolean(form[input.name])}
                      />
                    </div>
                  );
                } else if (input.type === "file") {
                  return (
                    <InputFile
                      key={index}
                      name={input.name}
                      type={input.type}
                      label={input.label}
                      onChange={onChange}
                      form={form}
                      setForm={setForm}
                    />
                  );
                } else {
                  return (
                    <div key={index} css={styles.inputContainer}>
                      <label css={styles.inputLabel}>{input.label}</label>
                      <input
                        css={styles.inputInput}
                        autoComplete="off"
                        name={input.name}
                        placeholder={input.placeholder}
                        type={input.type}
                        value={form[input.name]}
                        onChange={onChange}
                      />
                    </div>
                  );
                }
              })}
            </>
          </Container>

          <footer css={styles.footer}>
            <Container css={styles.footerContainer}>
              <button
                disabled={loading}
                css={styles.footerButton(!loading)}
                onClick={onSubmit}
              >
                {loading
                  ? isEdit
                    ? "Editando.."
                    : "Creando..."
                  : isEdit
                  ? "Editar"
                  : "Crear"}
              </button>
            </Container>
          </footer>
        </>
      )}
    </section>
  );
};
