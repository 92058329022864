import { css } from "@emotion/react";
import { breakpoints } from "../types";
import { rem } from "../utils";

export const containerTwoColumnsLeft = css`
  @media (min-width: ${breakpoints.lg}px) {
    margin-left: auto;
    max-width: calc(${1600 / 2}px - var(--space-xl) - var(--space-md));
  }
`;

export const containerTwoColumnsRight = css`
  @media (min-width: ${breakpoints.lg}px) {
    margin-right: auto;
    max-width: calc(${1600 / 2}px - var(--space-xl) - var(--space-md));
  }
`;

export const ellipsisText = css`
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${breakpoints.sm - 1}px) {
    max-width: ${rem(240)};
  }
`;
