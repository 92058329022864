/** @jsxImportSource @emotion/react */
import { createRef, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "reaptcha";

import { environments } from "../../../enviroments";
import { Container, useAlertListener } from "../../../ui";
import * as styles from "./styles";

export interface InputsProps {
  name: string;
  title: string;
  type: string;
  placeholder: string;
}

export interface ScaffoldAuthProps {
  title: string;
  buttonText: string;
  inputs: InputsProps[];
  service: (form: any) => Promise<any>;
  navigateSuccess: (body: any) => Promise<any>;
  position?: styles.Position;
  src?: string;
  footer?: JSX.Element | JSX.Element[];
}

export const ScaffoldAuth = ({
  title,
  buttonText,
  inputs,
  service,
  navigateSuccess,
  position = "left",
  src = "../pages/auth/background.png",
  footer,
}: ScaffoldAuthProps) => {
  const recaptchaRef = createRef() as any;
  const [form, setForm] = useState<any>({});
  const { addAlert } = useAlertListener();

  const onChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setForm((state: any) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (recaptchaRef?.current?.execute) {
      recaptchaRef.current.execute();
    }
  };

  const onReCAPTCHAChange = async (captchaCode: string | null) => {
    if (!captchaCode) return;

    const resp = await service({
      ...form,
      captchaCode,
    });
    const data = resp.data;

    if (data.status) {
      addAlert({ title: data.message, type: "success" });
      navigateSuccess(data.body);
    } else {
      addAlert({ title: data.message, type: "alert" });
    }

    if (recaptchaRef?.current?.reset) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <section css={styles.section}>
      <Container css={styles.container(position)}>
        <>
          <h1 css={styles.title}>{title}</h1>
          <form css={styles.form} onSubmit={onSubmit}>
            {inputs.map((data, index) => {
              return (
                <div key={index} css={styles.inputContainer}>
                  <label css={styles.inputTitle}>{data.title}</label>
                  <input
                    css={styles.input}
                    name={data.name}
                    type={data.type}
                    autoComplete="off"
                    placeholder={data.placeholder}
                    onChange={onChange}
                  />
                </div>
              );
            })}
            <button type="submit" css={styles.buttonContainer}>
              <a css={styles.button}>{buttonText}</a>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </form>
          <footer css={styles.footer}>{footer && footer}</footer>
          <ReCAPTCHA
            id="recaptcha"
            ref={recaptchaRef}
            size="invisible"
            sitekey={environments.REACT_APP_RECAPTCHA_SITE_KEY}
            onVerify={onReCAPTCHAChange}
          />
        </>
      </Container>
      <div css={styles.background(position, src)}>
        <img
          css={styles.backgroundLogo}
          src="../branding/logo.png"
          alt="MCNO logo"
        />
      </div>
    </section>
  );
};
