/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./styles";

export interface AlertProps {
  title: string;
  type: styles.AlertType;
  id?: string;
  className?: string;
  onClose?: (id?: string) => void;
}

export const Alert = ({ id, onClose, type, title, className }: AlertProps) => {
  return (
    <article
      css={styles.article(type)}
      className={`animate__animated ${className}`}
    >
      {type === "alert" ? (
        <FontAwesomeIcon icon={faInfo} />
      ) : (
        <FontAwesomeIcon icon={faCheck} />
      )}

      <p css={styles.title}>{title}</p>
      <FontAwesomeIcon
        className="close"
        icon={faTimes}
        onClick={() => (onClose ? onClose(id) : null)}
      />
    </article>
  );
};
