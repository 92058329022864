export const environments = {
  PORT: process.env.PORT || "",
  REACT_APP_PORT: process.env.REACT_APP_PORT || "",
  API: process.env.REACT_APP_API_URL || "",
  REACT_APP_RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || "",
  NODE_ENV: process.env.NODE_ENV || "",
  REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV || "",
  PUBLIC_URL: process.env.PUBLIC_URL || "",
  REACT_APP_PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL || "",
};
