import { createContext, useContext, useEffect, useState } from "react";
import { services } from "../services";
import { useLocation, useNavigate } from "react-router-dom";

export interface Auth {
  _id: string;
  name: string;
  email: string;
  permissions: {
    users: boolean;
    news: boolean;
    blogs: boolean;
    prospects: boolean;
  };
}

interface AuthContextProps {
  auth?: Auth | "w";
  setAuth: React.Dispatch<React.SetStateAction<Auth | undefined | "w">>;
}

const AuthContext = createContext<AuthContextProps>({
  auth: "w",
  setAuth: undefined as any,
});

interface AuthProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [auth, setAuth] = useState<Auth | undefined | "w">("w");

  useEffect(() => {
    getSession();
  }, []);

  const getSession = async () => {
    try {
      if (location.pathname === "/login" || location.pathname.includes("/auth"))
        return;

      const resp = await services.session();

      const data = resp?.data;
      if (data) {
        if (data?.status) {
          setAuth(data?.body);
          return;
        }
      }

      setAuth(undefined);
      navigate("/login");
    } catch (error) {
      setAuth(undefined);
      navigate("/login");
    }
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthListener = () => {
  return useContext(AuthContext);
};
