/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";

import { services } from "../../../services";
import { Card, ListComponent, ScaffoldMain } from "../../../ui";

import * as styles from "./styles";

export const ProspectsList = () => {
  const navigate = useNavigate();

  const onNavigate = (id: string) => {
    navigate(`/prospects/${id}`);
  };

  return (
    <ScaffoldMain root="prospects" title="Prospectos">
      <ListComponent
        root="/prospects"
        messageEmpty="Sin prospectos"
        service={services.prospectsList}
        extraFilters={[
          {
            type: "options-buttons",
            options: [
              {
                label: "Contactado",
                value: true,
              },
              {
                label: "No contactado",
                value: false,
              },
              {
                label: "Todos",
                value: null,
              },
            ],
            value: null,
            name: "contacted",
          },
        ]}
        child={(doc: any) => {
          return (
            <Card
              checked={doc.contacted}
              title={doc.name}
              description={doc.email}
              onClick={() => onNavigate(doc._id)}
            />
          );
        }}
      />
    </ScaffoldMain>
  );
};
