import { rem } from '../utils';
import { isSizeType, ThemeSizesTypes } from '../types';

export const row = (value: number | ThemeSizesTypes) => {
  let space = '';

  if (isSizeType(value)) {
    space = `var(--space-${value})`;
  } else {
    space = rem(value);
  }

  return `
        display: flex;
        flex-direction: row;
        gap: ${space};
        flex-wrap: nowrap;

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
            > * {
                margin-right: ${space};
                margin-bottom: ${space};
            }

            > :last-child {
                margin-right: 0px;
            }
        }

        @supports (not (gap: 0px)) {
            > * {
                margin-right: ${space};
                margin-bottom: ${space};
            }

            > :last-child {
                margin-right: 0px;
            }
        }
    `;
};
