/** @jsxImportSource @emotion/react */
import * as styles from "./styles";

export interface InputTextAreaProps {
  onChange: (event: any) => void;
  form: any;
  label: string;
  name: string;
  placeholder: string;
}

export const InputTextArea = ({
  label,
  name,
  placeholder,
  form,
  onChange,
}: InputTextAreaProps) => {
  return (
    <div css={styles.inputContainer}>
      <label css={styles.inputLabel}>{label}</label>
      <textarea
        css={styles.inputInput}
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        value={form[name]}
        onChange={onChange}
      />
    </div>
  );
};
