import { css } from "@emotion/react";

import * as ui from "../../../ui";

export type AlertType = "success" | "alert";

export const article = (type: AlertType) => css`
  ${ui.row("sm")};
  ${ui.flexCenter()};
  padding: ${ui.rem("sm")} ${ui.rem("sm")};
  border: 1px solid #bdbdbd;
  position: fixed;
  top: ${ui.rem("sm")};
  right: ${ui.rem("sm")};
  min-width: ${ui.rem(250)};
  box-shadow: 1px 1px 1px #6a6a6a;
  background-color: #ffffff;
  z-index: 3;

  svg {
    font-size: ${ui.rem(15)};
    color: ${type === "success" ? " #218116" : " #ec6036"};
  }

  svg.close {
    cursor: pointer;
    margin-left: auto;
  }
`;

export const title = css`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: ${ui.rem(14)};
  line-height: ${ui.rem(30)};
`;
